/* @import url("https://cdn.jsdelivr.net/npm/bootstrap-icons@1.8.1/font/bootstrap-icons.css"); */
@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300;400;600;700;800&display=swap");

body {
  margin: 0;
  /* font-family: Graphik,system-ui,-apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Nunito Sans", sans-serif;
  font-size: 0.875rem;
}

/* html, body,#root,  .App, .App>div {
    height: 100%!important;
  } */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.switch-handler .form-check-input {
  width: 52px !important;
  height: 28px;
}

.form-check-input:checked {
  /* background-color: #4bdb62;
border-color: #4bdb62; */
  background-color: #4285f4 !important;
  border-color: #4285f4 !important;
}

.form-check-input:focus {
  box-shadow: none;
}

.card-header {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

/* .row {
    margin-left: 0px;
    margin-right: 0px;
} */

.Content-container {
  border: 2px solid rgba(0, 0, 0, 0.05) !important;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
  border-radius: 8px !important;
}

.alphabets {
  color: #737376;
  font-size: 14px;
  height: 42px;
  width: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.text-box-alphabets {
  font-size: 18px;
  font-weight: 700;
}

/* .Content-container.accordion-button::after{
  color: #222;
    background-color: #f1f1f1;
    padding: 12px 16px;
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
    border-color: rgba(0,0,0,.2);
    border-radius: 6px;
}  */

.content-heading {
  font-size: 18px;
}

.text-box-container {
  /* box-shadow: 0 24px 0 0 #fff, 0 -24px 0 0 #fff, 1px 0 2px 0 rgb(0 0 0 / 10%), -1px 0 2px 0 rgb(0 0 0 / 10%); */
  width: 460px;
  margin-left: 3rem;
  position: relative;
}

.reply-row-block svg {
  margin-right: 10px;
}

.reply-row-block span {
  color: #737376;
}

.next-path-heading {
  color: #737376;
  font-size: 13px;
  margin-bottom: 8px;
}

.text-gray {
  color: #737376;
}

.reply-block-container {
  margin-right: 0px !important;
  margin-left: 0px !important;
}

/* .table-responsive{
    overflow-x: inherit;
} */

.proactive-checkbox {
  margin-left: 7rem;
}

.proactive-checkbox .input-field {
  width: 50px !important;
  margin: 0px;
  min-width: 100px !important;
}

.proactive-checkbox .form-check-input {
  height: 20px !important;
  width: 20px !important;
  /* background-color: #c1d4e7; */
  border-color: #ccc !important;
}

.textBox {
  /* max-width: 300px; */
  width: 100%;
  min-width: 260px;
  padding: 15px 24px 4px 15px;
  /* width: fit-content; */
  height: 120px;
  background-color: #f1f1f1;
  border-radius: 5px;
  text-align: left;
}

.bot-image {
  width: 300px;
}

.empty-bot-image {
  display: flex;
  align-items: center;
  margin: 0px auto;
  width: 60%;
  padding-top: 5%;
  padding-bottom: 20px;
}

.empty-bot-content {
  padding-left: 30px;
  padding-right: 30px;
}

.text-button-container {
  margin-bottom: 30px;
  padding-right: 30px !important;
  margin-top: 4px;
  border-radius: 8px;
  min-height: 170px;
  width: 100%;
  box-shadow: 0 24px 0 0 #fff, 0 -24px 0 0 #fff, 1px 0 2px 0 rgb(0 0 0 / 10%),
    -1px 0 2px 0 rgb(0 0 0 / 10%);
  /* box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%), 0 -10px 0 0 #fff, 1px 0 2px 0 rgb(0 0 0 / 10%), -1px 0 2px 0 rgb(0 0 0 / 10%); */
}

.accordion-button:not(.collapsed) {
  color: #000 !important;
  background-color: #fff !important;
  /* border: 2px solid rgba(0,0,0,.05); */
  box-shadow: none !important;
}

.chat-bot-accordion {
  box-shadow: 0 4px 16px rgb(0 0 0 / 8%);
  border-radius: 8px;
}

.path-name-input input {
  border: none !important;
  outline: none;
  font-size: 18px;
  width: 100%;
  min-width: 100px;
  /* max-width: 1450px; */
}

.path-name-input input:hover {
  border-bottom: 1px dashed rgba(0, 0, 0, 0.2) !important;
}

.path-name-input input {
  border-bottom: 1px dashed transparent !important;
}

.accordion-button:focus {
  background-color: #fff !important;
  box-shadow: none !important;
  border-radius: 8px !important;
  border-bottom-left-radius: 0px !important;
  border-bottom-right-radius: 0px !important;
}

.chatbot-expanded-accordion {
  border: 2px solid rgba(0, 0, 0, 0.05) !important;
  border-top: none !important;
}

.inputs-column {
  border-right: 1px solid rgba(0, 0, 0, 0.1);
}

.content-row {
  /* border-top: 1px solid rgba(0,0,0,.1); */
  margin: 0px !important;
}

.file-box {
  width: 236px;
  /* padding: 10px 0; */
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  font-size: 15px;
  line-height: 20px;
  margin: 0px 0px 8px 0px !important;
  overflow-wrap: break-word;
}

/* .create-bot-button{
    margin-right: 10px;
} */

/* .btn-outline-warning{
    color: #ff7342!important;
    border-color: #ff7342!important;
    background-color: transparent!important;
    font-size: 13px !important;
    box-shadow: none;
    line-height: 32px !important;
    padding: 0 17px !important;
    border-radius: 4px !important;

} */

.file-box.selected {
  border-color: #80bdff !important;
  box-shadow: 0 4px 14px 0 rgb(13 36 209 / 10%);
}

.add-new-path-button-container {
  margin-left: 20px;
}

.add-input-button {
  color: #334bfa !important;
  font-weight: 700 !important;
  font-size: 14px !important;
}

.text-box-column-0 .text-box-actions-column {
  display: none !important;
}

.text-box-container:hover .text-box-actions-column {
  display: block;
}

.textBox {
  border: none;
}

.textBox:focus-visible {
  outline-color: #80bdff !important;
  outline-style: solid;
  outline-width: 1px;
}

.text-box-actions-column {
  display: none;
  position: absolute;
  top: 1%;
  left: 102%;
}

.delete-svg-icon,
.drag-svg-icon,
.edit-svg-icon {
  width: 16px;
}

.delete-svg-icon path,
.drag-svg-icon path {
  fill: #737376;
}

.text-box-column {
  position: relative;
  margin-left: 2.5rem;
}

.file-box:hover .delete-option-svg {
  display: inline-block !important;
}

.file-box-0:hover .delete-option-svg {
  display: none !important;
}

.text-box-actions-column:hover {
  display: block !important;
}

.delete-option-svg {
  display: none;
  position: absolute;
  right: 10px;
  fill: #737376;
  width: 15px;
}
.delete-option-svg:hover {
  fill: #334bfa;
}

.input-field-container:hover .input-icon-container {
  display: block !important;
}

.input-icon-container {
  display: none;
  margin-right: 10px;
  margin-top: 4px;
}

.file-box:hover {
  border-color: #80bdff !important;
  box-shadow: 0 4px 14px 0 rgb(13 36 209 / 10%);
}

.new-path-button {
  background-color: #f1f1f1;
  font-size: 14px;
  line-height: 20px;
  padding: 8px 12px;
  width: 100%;
  font-weight: 600;
  border-radius: 6px;
  border: none;
}
.new-path-button:hover {
  color: #334bfa;
  background-color: #e3e7fa;
}
.input-field {
  min-width: 300px;
  /* max-width: 300px;  */
  height: 40px;
  width: 320px !important;
  background-color: rgb(230, 233, 254) !important;
  border: none !important;
  color: rgb(4, 21, 149) !important;
  margin-bottom: 10px;
  padding: 6px 11px;
  border-radius: 6px !important;
  font-size: 14px !important;
}

.bot-icon-container {
  position: relative;
}

.dialogue-name-dragicon {
  width: 100%;
}

.dialogue-name-dragicon svg {
  width: 12px;
  height: 12px;
}

.right-hand-svg {
  width: 25px;
  height: 25px;
}

.bot-icon {
  /* background-color: #737376; */
  color: #fff;
  border-radius: 50%;
  width: 30px !important;
  height: 30px;
  margin-right: 12px;
  margin-bottom: 5px;
  position: absolute;
  bottom: 3%;
  left: -5px;
}

.bot-icon svg {
  margin: 5px;
  width: 30px;
  height: 30px;
  fill: #000;
}

.input-field::placeholder {
  color: rgb(4, 21, 149) !important;
}

.form-select:focus {
  box-shadow: none !important;
}

.input-field:focus-visible {
  outline-color: rgb(4, 21, 149) !important;
  outline-style: solid;
  outline-width: 1px;
}

.path-dropdown {
  margin-bottom: 10px !important;
  background-color: rgb(230, 233, 254) !important;
  border: none !important;
  color: rgb(4, 21, 149) !important;
  border-radius: 6px !important;
  font-size: 14px !important;
}

.text-grey {
  color: var(--text-black);
}

.conditions-box {
  position: relative;
  border-left: 1px dashed rgba(0, 0, 0, 0.2);
}

.conditions-box::before {
  position: absolute !important;
  border-left: 1px dashed rgba(0, 0, 0, 0.2);
  top: -24px;
  height: 24px;
}

.conditon-card {
  border: 1px solid rgba(0, 0, 0, 0.2);
  padding: 20px;
  margin-bottom: 24px;
  position: relative;
  min-width: 450px;
  width: fit-content;
}

.conditon-card:before {
  content: "";
  position: absolute;
  left: -24px;
  width: 24px;
  top: 36px;
  border-top: 1px dashed rgba(0, 0, 0, 0.2);
}

.condition-type {
  width: 56px;
  height: 32px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-right: 0;
  border-radius: 6px 0 0 6px;
  display: block;
  text-align: center;
  background-color: #fff;
  color: #737376;
  padding: 5px 0 7px;
  font-size: 14px;
  font-weight: 500;
}

.file-box-0 path {
  fill: #737376;
}

/* ----------------------------- Bot List Css -------------------------- */

.main-content {
  padding: 25px;
}

.user-icon img {
  margin-right: 10px;
  min-width: 24px;
  width: 24px;
  height: 24px;
  line-height: 24px;
  font-size: 10px;
  border-radius: 50%;
  background-clip: padding-box;
  display: inline-block;
  text-align: center;
  font-weight: 500;
  color: #fff;
  vertical-align: bottom;
  position: relative;
  box-sizing: border-box;
}

.state-status {
  background-color: #e8e8e8;
  border-color: #e8e8e8;
  color: #737376;
  height: 20px;
  padding: 0 8px;
  display: inline-block;
  font-size: 13px;
  line-height: 20px;
  font-weight: 500;
  vertical-align: bottom;
  border-radius: 4px;
}

.table {
  --bs-table-hover-bg: rgba(0, 0, 0, 0.04);
  margin-bottom: 0;
}
.table > tbody > tr:last-child > th {
  border-bottom: 0px;
}
.table > thead > tr > th {
  border-bottom: 1px solid #f1f1f1;
}
.table tr th:first-child,
.table tr td:first-child {
  padding-left: 30px;
}

.table > tbody > tr > td a {
  text-decoration: none;
}

.table > tbody > tr > td {
  border-bottom: 1px solid #f1f1f1;
}

.table > :not(:first-child) {
  border: 1px solid transparent !important;
}

.table > thead > tr > th {
  font-size: 14px;
  color: #43506a;
  font-weight: 600;
  letter-spacing: 0.01em;
  /* border-right: 1px solid rgb(246, 246, 246); */
  padding: 22px 10px;
}
.table > tbody > tr > td {
  padding: 18px 10px;
  text-decoration: none;
  color: #7b7e86;
  font-weight: 400;
  font-size: 14px;
}
.panel {
  background-color: #fff;
  margin-bottom: 25px;
  box-shadow: 0 0 4px 0 rgb(0 0 0 / 4%);
  border-width: 0;
  border-radius: 3px;
  border: 1px solid #ddd;
}

.page-head {
  padding: 20px 25px 0px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.page-head .small {
  font-size: 13px;
  color: #a2aabb;
  margin-left: 7px;
}

.page-heading {
  margin-top: 0px;
  margin-bottom: 0px;
  letter-spacing: normal;
  text-transform: none;
  color: #43506a;
  font-weight: 500;
  font-size: 18px;
}

.btn:focus {
  box-shadow: none !important;
}

.accordion-button::after {
  background: none !important;
}

.btn-secondary {
  color: #f2603e !important;
  background-color: #fff !important;
  border: 1px solid #f2603e !important;
  font-weight: 400 !important;
  text-transform: none !important;
  font-size: 13px !important;
}

.btn-primary {
  font-size: 13px !important;
  background-color: #f2603e !important;
  border-color: #f2603e !important;
  /* margin-right: 12px; */
  box-shadow: none;
  line-height: 32px !important;
  padding: 0 17px !important;
  border-radius: 4px !important;
}

.btn-danger {
  font-size: 13px !important;
  /* box-shadow: none; */
  /* line-height: 32px !important; */
  /* padding: 0 17px !important; */
  background-color: #f2603e !important;
  border-color: #f2603e !important;
  border-right: 1px solid transparent;
}

.btn-danger:hover {
  background-color: #ec7a60 !important;
}

.btn-primary:hover {
  box-shadow: none;
  background-color: #fd7656 !important;
  border-color: #fd7656 !important;
}

.btn-secondary:hover {
  box-shadow: none;
  color: #f2603e !important;
  background-color: #feedea !important;
  border-color: #f2603e !important;
}

.eb-bot-bg-container {
  background-color: #f6f8f9;
  height: 100%;
  min-height: 100vh;
}

/* modal styles */

.modal-header {
  border: 0;
  background-color: #f6f6f6;
  border-bottom: 1px solid #eee;
  padding: 20px 30px !important;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.modal-body {
  padding: 30px 30px 30px !important;
}

.created-action-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position: relative;
}

.table > tbody > tr:hover .edit-icon,
.table > tbody > tr:hover .delete-icon {
  display: block !important;
}

.edit-icon {
  margin-right: 10px;
  display: none;
}

.delete-icon {
  display: none;
  cursor: pointer;
}

.action-icon-container {
  position: absolute;
  display: flex;
  flex-direction: row;
  position: absolute;
  right: 1%;
  padding: 0 1.5rem;
  /* display: none; */
  fill: #6c757d;
}

.table > tbody > tr > td {
  max-width: 300px;
}

@media (max-width: 1350px) {
  .input-field {
    width: 100% !important;
    min-width: 100% !important;
  }
}

@media screen and (min-width: 768px) {
  .eb-botName {
    white-space: nowrap;
    text-overflow: ellipsis;
    width: 100%;
    overflow: hidden;
  }

  .path-name-input input {
    max-width: 1450px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1120px) {
  .text-box-container {
    width: 100% !important;
    margin-left: 0 !important;
  }

  .proactive-checkbox {
    margin-left: 4rem;
  }
}

@media (max-width: 350px) {
  .custom-page-head {
    flex-direction: column;
  }
  .page-heading {
    margin-bottom: 10px;
  }
}
@media (max-width: 767px) {
  /* .custom-page-head{
        flex-direction: column;
    } */

  .file-box {
    width: 100%;
  }
  .text-button-container {
    margin-left: 10px !important;
    padding-left: 20px;
  }

  .create-bot-button {
    display: none;
  }

  .table tr th:nth-child(2),
  .table tr td:nth-child(2) {
    padding-left: 30px;
  }

  .table > thead > tr > th:nth-child(1) {
    display: none;
  }

  .table > tbody > tr > td:nth-child(1) {
    display: none;
  }

  .table > thead > tr > th:nth-child(4) {
    display: none;
  }

  .table > thead > tr > th:nth-child(5) {
    display: none;
  }

  .table > thead > tr > th:nth-child(6) {
    display: none;
  }

  .table > tbody > tr > td:nth-child(4) {
    display: none;
  }

  .table > tbody > tr > td:nth-child(5) {
    display: none;
  }

  /* .table > tbody > tr > td:nth-child(6){
        display: none;
    } */

  .dialogue-editor-sidebar {
    width: 100% !important;
    min-width: 100% !important;
  }

  .proactive-checkbox {
    margin: 0px;
    flex-direction: column !important;
  }
  .inputs-column {
    border: none !important;
  }

  .textBox {
    min-width: 100%;
  }

  .delay-container {
    margin-top: 2rem;
  }

  .input-field {
    min-width: 100%;
    width: 100% !important;
  }
  /* 
    .dialogue-editor-sidebar{
        width: auto;
        min-width: auto;
        max-width:auto
    } */

  .diagolue-responsive-container {
    flex-direction: column !important;
  }

  .text-box-container {
    margin-left: 0px;
    width: auto;
  }
  .card-body {
    overflow: hidden;
  }
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 600px !important;
    width: 600px;
    margin: 30px auto !important;
  }
}

.modal-body span {
  font-size: 13px;
}

.btn-close {
  font-size: 10px;
  opacity: 0.4 !important;
  text-shadow: none;
}

.modal-title {
  font-size: 17px !important;
  color: #404040;
  font-weight: 300;
}

.required {
  color: red;
  margin-left: 4px;
}

.modal-input {
  height: 40px;
  font-size: 13px;
  border-radius: 4px;
  border-top-color: transparant !important;
  border: 1px solid #eae9e9;
  margin-bottom: 20px;
  box-shadow: none;
  padding: 8px 12px;
  width: 100%;
}

.modal-label {
  font-weight: 400;
  font-size: 13px;
  margin-bottom: 8px;
  color: #666;
  display: inline-block;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

/* // lighter badges */
.badge-light-primary {
  color: #3699ff;
  background-color: #e1f0ff;
}

.badge-light-success {
  color: #1bc5bd;
  background-color: #c9f7f5;
}

.badge-light-info {
  color: #8950fc;
  background-color: #eee5ff;
}

.badge-light-warning {
  color: #ffa800;
  background-color: #fff4de;
}

.badge-light-danger {
  color: #f64e60;
  background-color: #ffe2e5;
}

.badge-light-dark {
  color: #181c32;
  background-color: #d1d3e0;
}

/* // Cards */
.card {
  border-width: 0;
  -webkit-box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
  box-shadow: 0px 0px 30px 0px rgba(82, 63, 105, 0.05);
  margin-bottom: 24px;
}
.dialogue-editor-sidebar {
  width: 310px;
  min-width: 310px;
  max-width: 310px;
  background-color: #fff;
}
.dialogue-name-container {
  padding: 10px 12px 9px 15px;
  position: relative;
}
.dialogue-path-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  padding-left: 5px;
}

.media {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: start;
  align-items: flex-start;
}

.media-body {
  -ms-flex: 1;
  flex: 1;
}
.fs-14 {
  font-size: 14px;
}
.fs-16 {
  font-size: 16px;
}
.flex-none {
  flex: none;
}

.add-new-dialogue-dd {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding: 9px 0;
  /* background-color: red; */
}

select.form-control {
  text-indent: 16px;
}

.proactive-checkbox .form-check-input:checked[type="checkbox"] {
  background-image: url("/public/white-tick.svg");
  background-size: 14px;
}

.save-and-close-dropdown {
  cursor: pointer;
}

/* Publish Switch */

.switch {
  position: relative;
  display: inline-block;
  width: 130px;
  height: 38px;
}

.switch input {
  visibility: hidden;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border: 1px solid #dfdfdf;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 33px;
  width: 33px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
  box-shadow: 0 1px 3px rgb(0 0 0 / 40%);
}

input:checked + .slider {
  background-color: #64bd63 !important;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(91px);
}

/*------ ADDED CSS ---------*/
.slider:after {
  content: "Draft";
  color: #999;
  display: block;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 60%;
  font-size: 15px;
  font-family: Verdana, sans-serif;
}

input:checked + .slider:after {
  content: "Published";
  left: 40%;
  color: #fff;
}

.save-alert {
  position: absolute;
  top: 4%;
  left: 42%;
  background-color: #64bd63;
  color: #fff;
  z-index: 10;
}

.save-alert .btn-close svg {
  fill: #fff;
}
.save-alert .btn-close {
  background: none;
}

.eb-bot-bg-container .modal-dialog {
  margin-top: 80px !important;
}

.cursor-pointer {
  cursor: pointer;
}
